
// ------------  LOGIN ------------- //
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

// ------------  USER ------------- //
export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_RESEND_EMAIL_VERIFICATION = "USER_RESEND_EMAIL_VERIFICATION";
export const USER_SINGLE_DETAILS="USER_SINGLE_DETAILS";
export const UPDATE_USER_DETAILS="UPDATE_USER_DETAILS";

// ------------ SEATGEEK ---------- //
export const GET_TAXONOMY_SUCCESS = "GET_TAXONOMY_SUCCESS";
export const GET_TAXONOMY_FAILURE = "GET_TAXONOMY_FAILURE";
export const GET_SINGLE_EVENT_SUCCESS = "GET_SINGLE_EVENT_SUCCESS";
export const GET_GEOIP_EVENTS_SUCCESS = "GET_GEOIP_EVENTS_SUCCESS";
export const GET_RESULT_ON_HOME_SEARCH = "GET_RESULT_ON_HOME_SEARCH";
export const GET_EVENTS_BY_RECOMENDATIONS = "GET_EVENTS_BY_RECOMENDATIONS";
export const GET_TICKET_PURCHASED_ORDER = "GET_TICKET_PURCHASED_ORDER";
export const DELETE_TICKET_PURCHASED = "DELETE_TICKET_PURCHASED";
export const GET_GROUP_INFO_DATA = "GET_GROUP_INFO_DATA";
export const DELETE_GROUP_INFO_DATA = "DELETE_GROUP_INFO_DATA";

// ------------ SELL -------------- //
export const CREATE_TICKET_LISTING = "CREATE_TICKET_LISTING";
export const EVENT_TICKETS_LISTING = "EVENT_TICKETS_LISTING";
export const LIST_TICKETS_FOR_SALE = "LIST_TICKETS_FOR_SALE";
export const REMOVE_TICKET_FROM_LISTING = "REMOVE_TICKET_FROM_LISTING";
export const LIST_PURCHASED_TICKETS = "LIST_PURCHASED_TICKETS";
export const REMOVE_UPLOADED_FILE = "REMOVE_UPLOADED_FILE";

// ------------- CONTACT-FORM ------------ //
export const SEND_QUERY = "SEND_QUERY";

// ------------- Error -------------//
export const ERROR_CODE_500 = "ERROR_CODE_500";

// ------------ News -------------- //
export const LIST_NEWS = "LIST_NEWS";
export const GET_SINGLE_NEWS = "GET_SINGLE_NEWS";

// ------------- Support Staff ------------ //
export const RAISE_TICKET = "RAISE_TICKET";
export const LIST_RAISE_TICKET = "LIST_RAISE_TICKET";
export const SEND_TICKET_MESSAGE = "SEND_TICKET_MESSAGE";
export const LIST_TICKET_MESSAGES = "LIST_TICKET_MESSAGES";

// ------------- Event ------------ //

export const LIST_EVENT = "LIST_EVENT";
export const SINGLE_EVENT_ID = "SINGLE_EVENT_ID";
export const GET_RECOMEDATION_BY_EVENT = "GET_RECOMEDATION_BY_EVENT";
export const GET_SEARCH_EVENT = "GET_SEARCH_EVENT";
export const TICKET_PURCHASE = "TICKET_PURCHASE";
export const GET_SEARCH_EVENT_HOME = "GET_SEARCH_EVENT_HOME";
export const GET_E_TICKET = "GET_E_TICKET";
export const TICKET_LOCK = "TICKET_LOCK";
export const TICKET_PDF= "TICKET_PDF";
export const UPCOMING_EVENT_BY_GEOIP= "UPCOMING_EVENT_BY_GEOIP";
export const CATAGORIES_EVENT="CATAGORIES_EVENT";
export const GET_CITIES_SUGGESTION="GET_CITIES_SUGGESTION";
export const USER_SUBCRIBE="USER_SUBCRIBE";
export const TICKET_GROUP_INFO="TICKET_GROUP_INFO";
export const DELETE_TICKET_GROUP_DATA="DELETE_TICKET_GROUP_DATA";
export const GUEST_INFO="GUEST_INFO";
export const GET_GUEST_INFO_DATA = "GET_GUEST_INFO_DATA";


////////////////////////  payment types //////////////////////////////////

export const CREATE_ORDER_SUCCESS="CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILED="CREATE_ORDER_FAILED";

export const CAPTURE_ORDER_SUCCESS="CAPTURE_ORDER_SUCCESS"
export const CAPTURE_ORDER_FAILED="CAPTURE_ORDER_FAILED";
export const PAYMENT_COMMON_ERROR="PAYMENT_COMMON_ERROR";


//////////////////--FAQ-content--////////////////////////////

export const FAQS_LIST="FAQS_LIST" ;

export const BLOGS_LIST="BLOG_LIST" ;

export const SINGLE_BLOG ="SINGLE_BLOG" ;



////////////////////////////////////////////////////////


