import { 
    CAPTURE_ORDER_FAILED , 
    CAPTURE_ORDER_SUCCESS , 
    CREATE_ORDER_SUCCESS , 
    CREATE_ORDER_FAILED, 
    PAYMENT_COMMON_ERROR 
 } from "../actions/types";
 
 const initialState = {
    paymentError: null ,
    oderCreated: null,
    oderCaptued: null
 }
 
export default (state = initialState, action) => {
     switch (action.type) {
        case CAPTURE_ORDER_SUCCESS:
            return {
                ...state,
                oderCaptued: action.payload
            }
        case CAPTURE_ORDER_FAILED:
            return {
                ...state,
                paymentError: action.payload
            }
        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                oderCreated: action.payload
            }    
        case CREATE_ORDER_FAILED:
            return {
                ...state,
                paymentError: action.payload
            }        
        case PAYMENT_COMMON_ERROR:
            return {
                ...state,
                paymentError: action.payload
            }            
        default:
            return state;
    }
}