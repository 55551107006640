import { GET_TAXONOMY_SUCCESS, GET_TAXONOMY_FAILURE, GET_SINGLE_EVENT_SUCCESS, LIST_EVENT, SINGLE_EVENT_ID, GET_RECOMEDATION_BY_EVENT, GET_SEARCH_EVENT, TICKET_PURCHASE, GET_SEARCH_EVENT_HOME, GET_E_TICKET, TICKET_LOCK, GET_TICKET_PURCHASED_ORDER, DELETE_TICKET_PURCHASED, TICKET_PDF,UPCOMING_EVENT_BY_GEOIP,CATAGORIES_EVENT } from "../actions/types";

const initialState = { eventsByCategory: '' }

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TAXONOMY_SUCCESS:
            return {
                ...state,
                eventsByCategory: action.payload
            }
        case GET_TAXONOMY_FAILURE:
            return {
                ...state,
                eventsByCategory: action.payload
            }
        case GET_SINGLE_EVENT_SUCCESS:
            return {
                ...state,
                eventsByCategory: action.payload
            }
        case LIST_EVENT:
            return {
                ...state,
                events: action.payload
            }
        case SINGLE_EVENT_ID:
            return {
                ...state,
                singleEventData: action.payload
            }
        case GET_RECOMEDATION_BY_EVENT:
            return {
                ...state,
                recomedation_ID: action.payload
            }
        case GET_SEARCH_EVENT:
            return {
                ...state,
                search: action.payload
            }
        case GET_SEARCH_EVENT_HOME:
            return {
                ...state,
                searchEventHome: action.payload
            }
        case TICKET_PURCHASE:
            return {
                ...state,
                SingleEventTicketData: action.payload
            }
        case GET_E_TICKET:
            return {
                ...state,
                eTicket: action.payload
            }
        case TICKET_LOCK:
            return {
                ...state,
                lockTicket: action.payload
            }
        case GET_TICKET_PURCHASED_ORDER:
            return {
                ...state,
                ticketPurchasedOrderData: action.payload
            }
        case DELETE_TICKET_PURCHASED:
            return {
                ...state,
                deleteTicketOrder: action.payload
            }
        case TICKET_PDF:
            return {
                ...state,
                ticketPDF: action.payload
            }
            case UPCOMING_EVENT_BY_GEOIP:
                return {
                    ...state,
                    upComingEvent: action.payload
                }
            case CATAGORIES_EVENT:
                return {
                    ...state,
                    allCatagoriesEvent:action.payload
                }    

        default:
            return state;
    }
}


