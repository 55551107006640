import { 
   CREATE_TICKET_LISTING, EVENT_TICKETS_LISTING, LIST_PURCHASED_TICKETS, LIST_TICKETS_FOR_SALE, REMOVE_TICKET_FROM_LISTING, REMOVE_UPLOADED_FILE,TICKET_GROUP_INFO,GET_GROUP_INFO_DATA,DELETE_GROUP_INFO_DATA,
   GUEST_INFO,GET_GUEST_INFO_DATA
} from "../actions/types";

const initialState = {
    sellticket: '', deleteTicket:'', purchasedTicktes:'', removeFile:'' , ticketInfo:'',ticketInfoData:'',guestData:'',guestInfoData:''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TICKET_LISTING:
            return {
                ...state,
                sellticket: action.payload
            }
        case REMOVE_UPLOADED_FILE:
            return{
                ...state,
                removeFile:action.payload
            }
        case EVENT_TICKETS_LISTING:
            return {
                ...state,
                sellticket: action.payload
            }
        case LIST_TICKETS_FOR_SALE:
            return {
                ...state,
                sellticket: action.payload
            }
        case REMOVE_TICKET_FROM_LISTING:
            return {
                ...state,
                deleteTicket: action.payload
            }
        case LIST_PURCHASED_TICKETS:
            return {
                ...state,
                purchasedTickets: action.payload
            }
        case TICKET_GROUP_INFO:
            return{
                ...state,
                ticketInfo:action.payload
            }  
            case GET_GROUP_INFO_DATA:
                return {
                    ...state,
                    ticketInfoData: action.payload
                } 
                case DELETE_GROUP_INFO_DATA:
                    return{
                        ...state,
                        deleteticketinfoData:action.payload
                    } 
                    case GUEST_INFO:
                        return {
                            ...state,
                            guestData: action.payload
                        }
                        case GET_GUEST_INFO_DATA:
                            return {
                                ...state,
                                guestInfoData: action.payload
                            } 
        default:
            return state;
    }
}
