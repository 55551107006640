import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
// import { BrowserRouter as Router} from "react-router-dom";
// import { BrowserRouter , Router} from "react-router-dom";
import {HashRouter as Router , Link} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { hydrate, render } from "react-dom";


const rootElement = document.getElementById('root');


if (rootElement.hasChildNodes()) {
  // If there are already child nodes, use hydrate
  ReactDOM.hydrate(
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>,
    rootElement
  );
} else {
  // If there are no child nodes, use render
  ReactDOM.render(
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>,
    rootElement
  );
}


// ReactDOM.render(
//   <BrowserRouter>
//     <Router>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </Router>,
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


