import { GET_GEOIP_EVENTS_SUCCESS,GET_CITIES_SUGGESTION } from "../actions/types";

const initialState = { eventsByLocation: '',totalCitiesSuggestion:[] }

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GEOIP_EVENTS_SUCCESS:
            return {
                ...state,
                eventsByLocation: action.payload
            }
        case GET_CITIES_SUGGESTION:
            return {
                ...state,
                totalCitiesSuggestion: action.payload
            }
        default:
            return state;
    }
}
