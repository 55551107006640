import React, { Component , Suspense} from 'react';
import { connect } from 'react-redux';
// import Layout from './containers/Layout';
import './stylesheets/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
const Layout = React.lazy(() => import('./containers/Layout'));


class App extends Component { 

  
  render(){
    return (
      <Suspense fallback={<div></div>}>
        <Layout/>
      </Suspense>
    );
  }
}


const mapStateToProps = state => {
    const { loggedIn } = state.authentication;
    return { loggedIn }
}
export default connect( mapStateToProps, null )( App );
