import { FAQS_LIST  , BLOGS_LIST , SINGLE_BLOG} from "../actions/types";

const initialState = { faqsList:''}

export default (state = initialState, action) => {
    switch (action.type) {
        case FAQS_LIST:
            return {
                ...state,
                faqsList:action.payload
            }
        case BLOGS_LIST:
            return{
                ...state,
                blogsList:action.payload
            }
        case SINGLE_BLOG:
            return{
                ...state,
                singleBlog:action.payload
            }
        default:
            return state;
    }
}