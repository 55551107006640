import { 
    USER_SIGNUP,
    USER_FORGOT_PASSWORD,
    USER_RESEND_EMAIL_VERIFICATION, 
    SEND_QUERY,
    RAISE_TICKET,
    LIST_RAISE_TICKET,
    SEND_TICKET_MESSAGE,
    LIST_TICKET_MESSAGES,
    USER_SUBCRIBE,
    USER_SINGLE_DETAILS,
    UPDATE_USER_DETAILS
} from "../actions/types";

const initialState = { user: '', query:'' , UpdateUserDetails:'' }

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_SIGNUP:
            return {
                ...state,
                user: action.payload
            }
        case USER_RESEND_EMAIL_VERIFICATION:
            return {
                ...state,
                user:action.payload
            }
        case USER_FORGOT_PASSWORD:
            return {
                ...state,
                user:action.payload
            }
        case SEND_QUERY:
            return {
                ...state,
                query: action.payload
            }
        case RAISE_TICKET:
            return {
                ...state,
                raise_ticket: action.payload
            }
        case LIST_RAISE_TICKET:
            return {
                ...state,
                total_raise_tickets: action.payload
            }
        case SEND_TICKET_MESSAGE:
            return {
                ...state,
                ticket_message: action.payload
            }
        case LIST_TICKET_MESSAGES:
            return {
                ...state,
                total_ticket_messages: action.payload
            }
   
   
        case USER_SUBCRIBE:
            return {
                ...state,
                subcribe: action.payload
            }  
            case USER_SINGLE_DETAILS:
                return {
                    ...state,
                    UserDetails: action.payload
                }  
            case UPDATE_USER_DETAILS:
                return{
                    ...state,
                    UpdateUserDetails:action.payload
                }
        default:
            return state;
    }
}
